import SubmitButton from "@components/buttons/submit-button";
import InputField from "@components/input-field";
import ServiceSignInOptions from "@components/service-sign-in-options";
import { handleLoginSubmit } from "@helper/auth/auth-api-helper";
import AuthStore from "@helper/auth/auth-store";
import useUnAuthOnly from "@helper/custom-hooks/use-un-auth-only";
import GlobalHistoryStore from "@helper/global-history-store";
import { ILanguageHelper, normalLanguageHelper } from "@helper/static-language-helper";
import { removeLocalStorageVirtualTour } from "@helper/virtual-tour/virtual-tour-helper";
import { CheckIcon } from "@heroicons/react/solid";
import { NextPage } from "next";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import { toast } from "react-toastify";

export async function getStaticProps({ locale }: ILanguageHelper) {
    return normalLanguageHelper({ locale });
}

// TODO Work in progress
const IndexPage: NextPage = () => {
    useUnAuthOnly();
    const router = useRouter();
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const lastPage = GlobalHistoryStore((state) => state.lastPage);
    const { t } = useTranslation();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setLoading(true);
            const data = await handleLoginSubmit(e);
            if (data) {
                removeLocalStorageVirtualTour();
                const { user } = AuthStore.getState();
                const isAdmin = user.role === "admin";
                if (isAdmin) {
                    router.push("/admin-dashboard/users");
                    return;
                }
                if (lastPage !== "/" && lastPage !== "/login" && lastPage !== "/benefits") {
                    router.push(lastPage);
                } else {
                    router.push("/social");
                }
            } else {
                // toast(t('generic.error.incorrect_username_or_password'))
                toast("Incorrect username or password");
            }
        } catch (error) {
            toast("Fail to login ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto flex flex-col justify-center">
            <div className="flex h-full min-h-screen flex-row justify-center bg-rc-dark pb-10">
                <div className="w-full px-6 pt-40 md:w-1/2 md:px-0 uwd:w-1/5">
                    <div className="mb-10 flex justify-center md:mb-20">
                        <span className="font-headline cursor-pointer text-4xl font-bold text-white md:text-6xl">{t("generic.login")}</span>
                    </div>
                    <form onSubmit={handleLogin} className="space-y-10 ">
                        <InputField
                            defaultValue="example@example.com"
                            name="username"
                            label={`${t("generic.email")} / ${t("generic.username")}`}
                            type="text"
                            required
                        />
                        <InputField defaultValue="Feurisch95!141012!" name="password" label={t("generic.password")} type="password" required />
                        <div className="flex select-none flex-row items-center text-white">
                            <label className="inline-flex cursor-pointer">
                                <input
                                    type="checkbox"
                                    name="remember"
                                    checked={checkbox}
                                    onChange={() => setCheckbox(!checkbox)}
                                    value={checkbox ? "on" : ""}
                                    className="absolute size-5 cursor-pointer opacity-0"
                                />
                                <div className="mr-2 flex size-5 shrink-0 items-center justify-center rounded-md border-2 border-rc-gray-dark focus-within:border-rc-pink dark:border-rc-gray-dark">
                                    <CheckIcon className="fill-current pointer-events-none hidden size-5 text-rc-pink" />
                                </div>
                                <span className="ml-2 ">{t("generic.remember_me")}</span>
                            </label>
                        </div>
                        <SubmitButton title={t("generic.send")} loading={loading} disabled={loading} />
                    </form>
                    <div className="pt-2">
                        <Link href="/forgot-password">
                            <a className="text-white">{t("generic.forgot_passwort")}</a>
                        </Link>
                    </div>
                    <div className="mt-10 border-t border-rc-gray-dark pt-6 text-center text-white">
                        <span>{t("generic.login_via")}</span>
                    </div>

                    <ServiceSignInOptions />
                </div>
            </div>
        </div>
    );
};

export default IndexPage;
